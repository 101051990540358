import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

type TimezoneProps = {
  utcDate: string | null;
  targetTimezone: 'Asia/Hong_Kong' | 'UTC' ;
  format: string;
};

dayjs.extend(utc);
dayjs.extend(timezone);

const convertToTimezone = (
    { utcDate, targetTimezone = "Asia/Hong_Kong", format = "DD MMM YYYY" }: TimezoneProps
): string | null => {
  return utcDate == null ?  null : dayjs.utc(utcDate).tz(targetTimezone).format(format);
};

export default convertToTimezone;
