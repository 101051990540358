import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import RestoreIcon from "@mui/icons-material/Restore";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Grid,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import React, { useState, useCallback, useMemo } from "react";
import {
  EyeDetailsProps,
  TreatmentRecord,
} from "../../../utils/types/services/patients";
import TreatmentOtherInfoSymbol from "./TreatmentOtherInfoSymbol";
import TreatmentRecordHeader from "./TreatmentRecordHeader";
import CircleIcon from "@mui/icons-material/Circle";
import { chipColorMap } from "../../../utils/constants/cst-va-status-string";
import OctImageModal from "../OctImagesModal";
import useModal from "../../../hooks/useModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AEDataProps,
  getOctImages,
  toggleTreatmentStatus,
} from "../../../services/treatmentRecord";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import SimpleModal from "../../shareComponents/modal/SimpleModal";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../utils/constants/ga";
import { toast } from "react-toastify";
import toastIds from "../../../utils/constants/toastIds";
import convertToTimezone from "../../../utils/helperFunction/convertTimeZone";

const TABLE_HEADERS = [
  "patientDetailPage.octImage",
  "singlePharse.date",
  "singlePharse.drug",
  "Institute",
  "CST",
  "VA",
  "HEME",
  "PED",
  "IRF",
  "SRF",
] as const;

const TableCell = styled(MuiTableCell)(({ theme }) =>
  theme.unstable_sx({
    py: 1,
    px: 1,
  })
);

const tableRowSx = {
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "action.hover",
  },
} as const;

interface TreatmentRecordsProps {
  eyeDetails: EyeDetailsProps;
  records: TreatmentRecord[];
  handleAddTreatmentRecordClick: () => void;
  onRecordClick: (id: number) => void;
  tableHeightMuliplier: number;
  tableItemExpand: boolean;
  handleOpenAEModal: (data: AEDataProps) => void;
}

const TreatmentRecords = (props: TreatmentRecordsProps) => {
  const {
    eyeDetails,
    records,
    handleAddTreatmentRecordClick,
    onRecordClick,
    tableHeightMuliplier,
    tableItemExpand,
    handleOpenAEModal,
  } = props;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const NUM_2 = 2;
  const NUM_4 = 4;
  const NUM_6 = 6;
  const NUM_230 = 230;

  const {
    data: octImgData,
    mutateAsync,
    isLoading: isFetchingImage,
  } = useMutation(getOctImages);

  const { mutateAsync: mutateAsync2, isLoading } = useMutation(
    toggleTreatmentStatus
  );

  const {
    isOpen: isOctImgModalOpen,
    openModal: openOctImgModal,
    closeModal: closeOCtImageModal,
  } = useModal();

  const {
    isOpen: isObsoleteModalOpen,
    openModal: openObsoleteModal,
    closeModal: closeObsoleteModal,
  } = useModal();

  const [selected, setSelected] = useState<number[]>([]);

  const [obsoleteDetail, setObsoleteDetail] = useState<{
    id: number;
    header: string;
    body: string;
  }>({
    id: 0,
    header: "",
    body: "",
  });

  const handleDeleteRowClick = useCallback(
    (
      event: React.MouseEvent<unknown>,
      id: number,
      recordStatus: boolean,
      dateDisplay: string
    ) => {
      event.stopPropagation();
      const header = t(
        recordStatus
          ? "patientDetailPage.obsoletePrompt.obsoleteItemTitle"
          : "patientDetailPage.obsoletePrompt.reactiveItemTitle"
      ).replace("@Date", dateDisplay);
      const body = t(
        recordStatus
          ? "patientDetailPage.obsoletePrompt.obsoleteItemSubject"
          : "patientDetailPage.obsoletePrompt.reactiveItemSubject"
      ).replace("@Date", dateDisplay);

      setObsoleteDetail({
        id,
        header,
        body,
      });

      openObsoleteModal();
    },
    [openObsoleteModal, t]
  );

  const confirmObsoleteReactive = useCallback(async () => {
    const status = records.find(
      (e) => e.id === obsoleteDetail.id
    )!.recordStatus;
    const refetchResult = await mutateAsync2({
      id: obsoleteDetail.id,
      status: !status,
    });
    if (refetchResult.success) {
      if (refetchResult.data?.aeResult.exists) {
        handleOpenAEModal(refetchResult.data?.aeResult.data!);
      }

      queryClient.refetchQueries([
        REACT_QUERY_KEYS.PATIENT,
        refetchResult.data?.patientId,
      ]);
      closeObsoleteModal();
    }

    ReactGA.event({
      category: CategoryType.OtherButtonAction,
      action: OtherButtonActionType.Patient_Toggle_Treatment_Status,
      label: status.toString(),
    });
  }, [
    obsoleteDetail.id,
    mutateAsync2,
    records,
    closeObsoleteModal,
    handleOpenAEModal,
  ]);

  const [firstInjectionDate, totalInjections] = useMemo(() => {
    const activeRecords = records.filter((e) => e.recordStatus);
    const activeRecordsWithDrug = activeRecords.filter(
      (e) => e.drugId !== null
    );
    const activeRecordsWithDrugLength = activeRecordsWithDrug.length;
    const date =
      eyeDetails.firstInjectionDate === null
        ? activeRecordsWithDrugLength > 0
          ? activeRecordsWithDrug[activeRecordsWithDrugLength - 1].date
          : null
        : activeRecordsWithDrugLength > 0
        ? dayjs(eyeDetails.firstInjectionDate).diff(
            activeRecordsWithDrug[activeRecordsWithDrugLength - 1].date,
            "s"
          ) < 0
          ? eyeDetails.firstInjectionDate
          : activeRecordsWithDrug[activeRecordsWithDrugLength - 1].date
        : eyeDetails.firstInjectionDate;
    return [date, activeRecordsWithDrugLength + eyeDetails.pastInjectionCount];
  }, [records, eyeDetails.pastInjectionCount, eyeDetails.firstInjectionDate]);

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleCheckBoxClick = useCallback(
    (event: React.MouseEvent<unknown>, id: number) => {
      setSelected((val) => {
        const selectedIndex = val.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
          if (val.length === NUM_2) {
            toast.info("Max 2 selections", { toastId: toastIds.OCTIMGMAX2 });
            return val;
          }
          newSelected = newSelected.concat(val, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(val.slice(1));
        } else if (selectedIndex === val.length - 1) {
          newSelected = newSelected.concat(val.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            val.slice(0, selectedIndex),
            val.slice(selectedIndex + 1)
          );
        }

        return newSelected;
      });
    },
    []
  );

  const handleShowImageClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      const refetchResult = await mutateAsync(selected);
      if (refetchResult.success) openOctImgModal();
    },
    [mutateAsync, selected, openOctImgModal]
  );

  const toolTipMessage = (x: string, y: string, z: string) => {
    return (
      <React.Fragment>
        <Typography variant="subtitle1" color="inherit">
          Serial No: {x}
        </Typography>
        <Typography variant="subtitle1" color="inherit">
          Lot No: {z}
        </Typography>
        <Typography variant="subtitle1" color="inherit">
          Expiry: {y.substring(NUM_4, NUM_6) + y.substring(0, NUM_4)}
        </Typography>
      </React.Fragment>
    );
  };

  const renderDataRow = useCallback(
    (row: TreatmentRecord, index: number) => {
      const {
        id,
        date,
        drugName,
        cst,
        cstStatus,
        va,
        vaStatus,
        heme,
        ped,
        irf,
        srf,
        editImage,
        recordStatus,
        letterScoreDesc,
        serialNumber,
        expiryDate,
        lotNumber,
        treatedInstitute,
      } = row;
      const isItemSelected = isSelected(id);
      const e_color = recordStatus ? undefined : "text.disabled";
      const dateDisplay = convertToTimezone({utcDate: date, targetTimezone: "Asia/Hong_Kong", format: "DD MMM YYYY"});
      return (
        <TableRow key={id} onClick={() => onRecordClick(id)} sx={tableRowSx}>
          <TableCell
            onClick={(event) => {
              event.stopPropagation();
              editImage && handleCheckBoxClick(event, id);
            }}
          >
            <Checkbox
              sx={{ color: "primary.main" }}
              checked={isItemSelected}
              disabled={!editImage}
            />
          </TableCell>
          <TableCell sx={{ color: e_color }}>{dateDisplay}</TableCell>
          <TableCell sx={{ color: e_color }}>
            {serialNumber ? (
              <Tooltip
                title={toolTipMessage(serialNumber, expiryDate, lotNumber)}
                key={index}
                placement="right"
              >
                <Typography variant="subtitle1">{drugName}</Typography>
              </Tooltip>
            ) : (
              drugName ?? "-"
            )}
          </TableCell>
          <TableCell sx={{ color: e_color }}>
            {!tableItemExpand ? (
              <Tooltip title={treatedInstitute} key={index} placement="right">
                <Typography
                  sx={{
                    width: "90px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {treatedInstitute}
                </Typography>
              </Tooltip>
            ) : (
              <Typography>{treatedInstitute}</Typography>
            )}
          </TableCell>
          <TableCell sx={{ color: e_color }}>
            {tableItemExpand ? (
              <Grid container item xs alignItems="center">
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{ minWidth: "2vw" }}
                >
                  {cst}{" "}
                </Typography>
                {cstStatus !== null && (
                  <React.Fragment>
                    <CircleIcon
                      sx={{
                        fontSize: 10,
                        ml: 5,
                        color: recordStatus ? chipColorMap[cstStatus] : e_color,
                      }}
                    />
                    <Typography variant="subtitle2" fontWeight="bold">
                      {cstStatus}
                    </Typography>
                  </React.Fragment>
                )}
              </Grid>
            ) : (
              cst
            )}
          </TableCell>
          <TableCell sx={{ color: e_color }}>
            {tableItemExpand ? (
              <Grid container item xs alignItems="center">
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{ minWidth: "2vw" }}
                >
                  {va === null ? null : va === 0 ? letterScoreDesc : va}{" "}
                </Typography>
                {vaStatus !== null && (
                  <React.Fragment>
                    <CircleIcon
                      sx={{
                        fontSize: 10,
                        ml: 5,
                        color: recordStatus ? chipColorMap[vaStatus] : e_color,
                      }}
                    />
                    <Typography variant="subtitle2" fontWeight="bold">
                      {vaStatus}
                    </Typography>
                  </React.Fragment>
                )}
              </Grid>
            ) : va === null ? null : va === 0 ? (
              letterScoreDesc
            ) : (
              va
            )}
          </TableCell>
          <TableCell sx={{ color: e_color }}>
            <TreatmentOtherInfoSymbol checked={heme} />
          </TableCell>
          <TableCell sx={{ color: e_color }}>
            <TreatmentOtherInfoSymbol checked={ped} />
          </TableCell>
          <TableCell sx={{ color: e_color }}>
            <TreatmentOtherInfoSymbol checked={irf} />
          </TableCell>
          <TableCell sx={{ color: e_color }}>
            <TreatmentOtherInfoSymbol checked={srf} />
          </TableCell>
          {tableItemExpand && (
            <TableCell>
              <IconButton
                onClick={(event) =>
                  handleDeleteRowClick(event, id, recordStatus, dateDisplay ?? "")
                }
              >
                {recordStatus ? (
                  <PlaylistRemoveIcon fontSize="small" color="primary" />
                ) : (
                  <RestoreIcon fontSize="small" color="primary" />
                )}
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      );
    },
    [
      t,
      handleDeleteRowClick,
      handleCheckBoxClick,
      onRecordClick,
      tableItemExpand,
    ]
  );

  return (
    <Box sx={{ width: "100%", flexGrow: 1, p: 1 }}>
      <SimpleModal
        header={obsoleteDetail.header}
        body={obsoleteDetail.body}
        open={isObsoleteModalOpen}
        handleClose={closeObsoleteModal}
        buttons={{
          positiveButtonIsLoading: isLoading,
          positiveAction: confirmObsoleteReactive,
        }}
      />
      <OctImageModal
        open={isOctImgModalOpen}
        toggleModal={closeOCtImageModal}
        data={octImgData?.data ?? []}
      />

      <Grid item>
        <TreatmentRecordHeader
          firstInjectionDate={firstInjectionDate}
          totalInjections={totalInjections}
          handleAddTreatmentRecordClick={handleAddTreatmentRecordClick}
          handleShowImageClick={handleShowImageClick}
          isImageButtonDisabled={isFetchingImage || selected.length === 0}
        />
      </Grid>
      <TableContainer sx={{ maxHeight: NUM_230 * tableHeightMuliplier, mb: 4 }}>
        <Box sx={{ overflow: "auto" }}>
          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
            <Table stickyHeader sx={{ mt: 1 }}>
              <TableHead>
                <TableRow>
                  {_.concat(
                    TABLE_HEADERS,
                    tableItemExpand
                      ? ["patientDetailPage.obsoleteActivate"]
                      : []
                  ).map((header, index) => (
                    <TableCell key={index}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t(header)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{records.map(renderDataRow)}</TableBody>
            </Table>
          </Box>
        </Box>
      </TableContainer>
    </Box>
  );
};

export default TreatmentRecords;
