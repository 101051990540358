import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, Typography, Grid, FormLabel } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, SubmitErrorHandler } from "react-hook-form";
import * as yup from "yup";
import TabView, { TabItem } from "../../../shareComponents/tabView";
import { TreatmentRecord } from "../../../../utils/types/services/patients";
import { TreatmentModalMode } from "../../../../utils/types/ui/treatmentModalMode";
import { TreatmentRecordFormState } from "../../../../utils/types/ui/treatmentRecordForm";
import FormDatePicker from "../../../shareComponents/form/FormDatePicker";
import AdditionalDetailsForm from "./AdditionalDetailsForm";
import AssessmentForm from "./AssessmentForm";
import InjectionForm from "./InjectionForm";
import ReactGA from "react-ga4";
import {
  CategoryType,
  FormType,
  SubtleType,
} from "../../../../utils/constants/ga";
import {
  addTreatmentRecord,
  AEDataProps,
  updateTreatmentRecord,
} from "../../../../services/treatmentRecord";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../../utils/constants/reactQueryKeys";
import { Eye } from "../../../../utils/constants/general";
import { getAllDBConstants } from "../../../../services/dbConstants";
import BaseButton from "../../../shareComponents/button/BaseButton";
import SecondaryButton from "../../../shareComponents/button/SecondaryButton";
import base64TofileConvertor from "../../../../utils/helperFunction/base64TofileConvertor";
import _ from "lodash";
import { userGetData } from "../../../../services/user";

type DRUG_INFO = {
  drugId: number;
  serialNumber: string;
  lotNumber: string;
  expiryDate: string;
};

const REQ_NUMBER = "required number";
const REQ_INTEGER = "required integer";
const NUM_1200 = 1200;
const NUM_130 = 130;
const schema = yup.object().shape({
  octMachineId: yup
    .number()
    .typeError(REQ_NUMBER)
    .integer(REQ_INTEGER)
    .test(
      "test-has-cst",
      'required selection when "CST" is input',
      (val, data) => {
        if (data.parent.cst !== "" && val === -1) return false;
        return true;
      }
    )
    .required("required"),
  cst: yup.lazy((value: number | "") =>
    value === ""
      ? yup
          .string()
          .test(
            "test-has-id",
            'required input when "OCT Machine" is selected',
            (val, data) => {
              if (data.parent.octMachineId !== -1) return false;
              return true;
            }
          )
      : yup
          .number()
          .typeError(REQ_NUMBER)
          .integer(REQ_INTEGER)
          .max(NUM_1200, "required max 1200")
          .min(NUM_130, "required min 130")
  ),
  vaSnellen: yup
    .object()
    .nullable()
    .typeError("required object")
    .test(
      "test-has-id",
      'required selection when "Parts" is input',
      (val, data) => {
        if (val === undefined || val === null) return true;
        return Number.isInteger(val.id);
      }
    ),
  drugId: yup
    .number()
    .typeError(REQ_NUMBER)
    .integer(REQ_INTEGER)
    /*.test(
      "test-has-serial-number",
      'required selection when "Serial Number" is scanned',
      (val, data) => {
        if (data.parent.serialNumber !== "" && val === -1) return false;
        return true;
      }
    )*/
    .required("required"),
  treatmentDate: yup
    .date()
    .typeError("required date")
    .test("test-max-date", "required not greater than today", (val) => {
      if (val === undefined) return true;
      if (val === null) return false;
      return dayjs().endOf("D").diff(dayjs(val), "seconds") > 0;
    }),
  images: yup.array().min(0, "required").max(1, "required max 1 file"),
  editImage: yup.string().nullable(),
});
interface TreatmentInfoFormProps {
  handleCancelClick: () => void;
  mode: TreatmentModalMode;
  treatmentRecord?: TreatmentRecord | null;
  treatmentMethod: number;
  patientId: number;
  currentEye: Eye;
  handleOpenAEModal: (data: AEDataProps) => void;
  recordsDates: any;
}

const TreatmentInfoForm = (props: TreatmentInfoFormProps) => {
  const {
    handleCancelClick,
    mode,
    treatmentRecord,
    treatmentMethod,
    patientId,
    currentEye,
    handleOpenAEModal,
    recordsDates,
  } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [scannedDataValue, setScannedDataValue] = useState<string | null>(null);
  const [recordExists, setRecordExists] = useState<boolean>(false);
  const [serialNumberStatus, setSerialNumberStatus] = useState<{
    isused: boolean;
    isNotExist: boolean;
  }>({ isused: false, isNotExist: false });

  const getCurrentTabIndex = useCallback((val: number) => {
    setCurrentTab(val);
  }, []);

  const {
    control,
    handleSubmit,
    formState,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm<TreatmentRecordFormState>({
    defaultValues: {
      octMachineId: -1,
      cst: "",
      vaSnellen: null,
      drugId: -1,
      treatmentDate: null,
      treatedInstitute: "",
      images: [],
      editImage: null,
      heme: "0",
      irf: "0",
      ped: "0",
      srf: "0",
      serialNumber: "",
      lotNumber: "",
      expiryDate: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { data: dbConstants } = useQuery(
    [REACT_QUERY_KEYS.DB_CONSTANTS],
    getAllDBConstants
  );

  const { data: userData } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userGetData
  );

  const snellenTypes = dbConstants?.data?.snellenTypes ?? [];

  // Reset edit lock on tab close and browser close
  useEffect(() => {
    const handleTabClose = async (event: BeforeUnloadEvent) => {
      navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/api/patient/resetHcpEdit`,JSON.stringify({ event: "tab_closed" }));
      event.preventDefault();      
    };
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  useEffect(() => {
    if (!treatmentRecord) {
      setValue("treatedInstitute", userData?.data?.instituteName[0] ?? "");
    }
  }, [userData]);

  useEffect(() => {
    if (!treatmentRecord) return;

    const {
      drugId,
      cst,
      date,
      octMachine,
      heme,
      irf,
      ped,
      srf,
      vaSnellen,
      editImage,
      serialNumber,
      lotNumber,
      expiryDate,
      treatedInstitute,
    } = treatmentRecord;

    reset({
      octMachineId: octMachine ?? -1,
      cst: cst ?? "",
      vaSnellen: snellenTypes.find((e) => e.id === vaSnellen) ?? null,
      drugId: drugId ?? -1,
      treatmentDate: dayjs(date),
      treatedInstitute:
        treatedInstitute ?? userData?.data?.instituteName[0] ?? "",
      images: [],
      editImage,
      heme: heme === null ? "0" : heme,
      irf: irf === null ? "0" : irf,
      ped: ped === null ? "0" : ped,
      srf: srf === null ? "0" : srf,
      serialNumber: serialNumber ?? "",
      lotNumber: lotNumber ?? "",
      expiryDate: expiryDate ?? "",
    });
  }, [treatmentRecord, snellenTypes]);

  useEffect(() => {
    trigger("cst");
  }, [watch("octMachineId")]);

  useEffect(() => {
    trigger("octMachineId");
  }, [watch("cst")]);

  useEffect(() => {
    trigger("drugId");
  }, [watch("serialNumber"), watch("drugId")]);

  useEffect(() => {
    const selDate = getValues("treatmentDate");
    const selDrug = getValues("drugId");
    setRecordExists(false);
    if (selDate !== null && selDrug !== -1) {
      const formattedSelDate = dayjs(selDate).format("DD MMM YYYY");
      const tempData = { date: formattedSelDate, drug: selDrug };
      const findRecords = recordsDates.filter((obj: any) => {
        return obj.date === tempData.date && obj.drug === tempData.drug;
      });
      const isSameRecord = _.find(findRecords, { id: treatmentRecord?.id });
      if (
        (mode === "add" && findRecords.length !== 0) ||
        (mode === "edit" &&
          findRecords.length !== 0 &&
          isSameRecord === undefined)
      ) {
        setRecordExists(true);
      }
    }
  }, [watch("treatmentDate"), watch("drugId")]);

  const { mutateAsync } = useMutation(addTreatmentRecord);
  const { mutateAsync: mutateAsync2 } = useMutation(updateTreatmentRecord);

  const imageFiles = watch("images");
  const imageEditFile = watch("editImage");

  const checkDrugDataChanged = useCallback(
    (drugId: number, scanned: string | null) => {
      setValue("drugId", drugId);
      setScannedDataValue(null);
      setValue("serialNumber", "", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("lotNumber", "", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("expiryDate", "", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [setValue]
  );
  const setScannedDetails = useCallback(
    (scannedData: DRUG_INFO) => {
      setValue("serialNumber", scannedData.serialNumber, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("lotNumber", scannedData.lotNumber, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("expiryDate", scannedData.expiryDate, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("drugId", scannedData.drugId, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [setValue]
  );

  const removeImage = useCallback(
    (name: string) => {
      setValue("editImage", null, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue(
        "images",
        imageFiles.filter((image) => image.name !== name),
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        }
      );
    },
    [imageFiles, setValue]
  );

  const onDropImage = useCallback(
    (imgFiles: File[]) => {
      setValue("editImage", null, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("images", [...imgFiles], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [setValue]
  );

  const onCaptureImage = useCallback(
    (img: string | null) => {
      const convertedImage = img !== null ? base64TofileConvertor(img) : null;
      setValue("editImage", null, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("images", convertedImage !== null ? [convertedImage] : [], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [setValue]
  );

  const tabs: TabItem[] = useMemo(() => {
    const errors = formState.errors;
    const injectionText = t("treatmentRecordModal.injection");
    const assessmentText = t("treatmentRecordModal.assessment");
    const additionalDetailsText = t("treatmentRecordModal.additionalDetails");
    const ERR_MAIN = "error.main";
    return [
      {
        label: errors.drugId ? (
          <Typography sx={{ color: ERR_MAIN }}>{injectionText}</Typography>
        ) : (
          injectionText
        ),
        component: (
          <InjectionForm
            control={control}
            treatmentRecord={treatmentRecord}
            checkDrugDataChanged={checkDrugDataChanged}
            setScannedDetails={setScannedDetails}
            scannedDataValue={scannedDataValue}
            setScannedDataValue={setScannedDataValue}
            serialNumberStatus={serialNumberStatus}
            setSerialNumberStatus={setSerialNumberStatus}
          />
        ),
      },
      {
        label:
          errors.octMachineId || errors.cst || errors.vaSnellen ? (
            <Typography sx={{ color: ERR_MAIN }}>{assessmentText}</Typography>
          ) : (
            assessmentText
          ),
        component: <AssessmentForm control={control} />,
      },
      {
        label:
          errors.heme ||
          errors.irf ||
          errors.ped ||
          errors.srf ||
          errors.images ? (
            <Typography sx={{ color: ERR_MAIN }}>
              {additionalDetailsText}
            </Typography>
          ) : (
            additionalDetailsText
          ),
        component: (
          <AdditionalDetailsForm
            control={control}
            imageFiles={imageFiles}
            imageEditFile={imageEditFile}
            removeImage={removeImage}
            onDropImage={onDropImage}
            onCaptureImage={onCaptureImage}
          />
        ),
      },
    ];
  }, [
    control,
    imageFiles,
    imageEditFile,
    removeImage,
    onDropImage,
    t,
    formState,
  ]);

  const onSubmit = useCallback(
    async (data: TreatmentRecordFormState) => {
      const formData = new FormData();
      if (data.images.length === 1) {
        formData.append("octImage", data.images[0]);
      }
      formData.append("octMachineId", data.octMachineId.toString());
      formData.append("cst", data.cst.toString());
      formData.append("vaSnellen", data.vaSnellen?.id.toString() ?? "-1");
      formData.append(
        "letterScore",
        data.vaSnellen?.letterScore.toString() ?? "-1"
      );
      formData.append("drugId", data.drugId.toString());
      formData.append("treatmentDate", data.treatmentDate!.toISOString());
      formData.append("treatedInstitute", data.treatedInstitute);
      formData.append(
        "serialNumber",
        data.serialNumber ? data.serialNumber : ""
      );
      formData.append("expiryDate", data.expiryDate ? data.expiryDate : "");
      formData.append("lotNumber", data.lotNumber ? data.lotNumber : "");
      data.heme !== "0" && formData.append("heme", data.heme.toString());
      data.irf !== "0" && formData.append("irf", data.irf.toString());
      data.ped !== "0" && formData.append("ped", data.ped.toString());
      data.srf !== "0" && formData.append("srf", data.srf.toString());
      formData.append("treatmentPhaseId", treatmentMethod.toString());
      formData.append("currentEye", currentEye === Eye.LEFT ? "OS" : "OD");
      formData.append("patientId", patientId.toString());

      const result = await mutateAsync(formData);
      if (result.success) {
        if (result.data?.aeResult.exists) {
          handleOpenAEModal(result.data?.aeResult.data!);
        }

        queryClient.refetchQueries([REACT_QUERY_KEYS.PATIENT, patientId]);
        handleCancelClick();
      }
      ReactGA.event({
        category: CategoryType.FormSubmit,
        action: FormType.TreatmentInfoForm,
        label: SubtleType.Add,
        value: 200,
      });
    },
    [
      treatmentMethod,
      mutateAsync,
      handleCancelClick,
      patientId,
      currentEye,
      handleOpenAEModal,
    ]
  );

  const onSubmit2 = useCallback(
    async (data: TreatmentRecordFormState) => {
      const formData = new FormData();
      if (data.images.length === 1) {
        formData.append("octImage", data.images[0]);
      }
      formData.append("octMachineId", data.octMachineId.toString());
      formData.append("cst", data.cst.toString());
      formData.append("vaSnellen", data.vaSnellen?.id.toString() ?? "-1");
      formData.append(
        "letterScore",
        data.vaSnellen?.letterScore.toString() ?? "-1"
      );
      formData.append("drugId", data.drugId.toString());
      formData.append("treatmentDate", data.treatmentDate!.toISOString());
      formData.append("treatedInstitute", data.treatedInstitute);
      formData.append(
        "serialNumber",
        data.serialNumber ? data.serialNumber : ""
      );
      formData.append("expiryDate", data.expiryDate ? data.expiryDate : "");
      formData.append("lotNumber", data.lotNumber ? data.lotNumber : "");
      data.heme !== "0" && formData.append("heme", data.heme.toString());
      data.irf !== "0" && formData.append("irf", data.irf.toString());
      data.ped !== "0" && formData.append("ped", data.ped.toString());
      data.srf !== "0" && formData.append("srf", data.srf.toString());
      formData.append("patientId", patientId.toString());
      formData.append("treatmentId", treatmentRecord!.id.toString());
      formData.append("editImage", data.editImage?.toString() ?? "");

      const result = await mutateAsync2(formData);
      if (result.success) {
        if (result.data?.aeResult.exists) {
          handleOpenAEModal(result.data?.aeResult.data!);
        }

        queryClient.refetchQueries([REACT_QUERY_KEYS.PATIENT, patientId]);
        handleCancelClick();
      }

      ReactGA.event({
        category: CategoryType.FormSubmit,
        action: FormType.TreatmentInfoForm,
        label: SubtleType.Edit,
        value: 200,
      });
    },
    [
      mutateAsync2,
      handleCancelClick,
      patientId,
      treatmentRecord?.id,
      handleOpenAEModal,
    ]
  );

  const handleSubmitError: SubmitErrorHandler<TreatmentRecordFormState> =
    useCallback((error) => {
      const fields = Object.keys(error);
      ReactGA.event({
        category: `${CategoryType.FormValidationError}_Main_Treatment`,
        action: FormType.TreatmentInfoForm,
        label: `${fields.flatMap((e) => e)}`,
        nonInteraction: false,
        value: 400,
      });
    }, []);

  return (
    <Grid container flexDirection="row" sx={{ minHeight: "95%" }}>
      <Grid item xs={12}>
        <FormControl sx={{ display: "block", mt: 0.7 }}>
          <FormLabel
            error={control._formState.errors.hasOwnProperty("treatmentDate")}
            className="labelStyles"
          >
            Date
          </FormLabel>
          <FormDatePicker<TreatmentRecordFormState>
            control={control}
            name="treatmentDate"
            inputFormat="DD/MM/YYYY"
            maxDate={dayjs()}
            placeholder="Enter Date"
          />
        </FormControl>
        <TabView
          tabs={tabs}
          getCurrentTabIndex={getCurrentTabIndex}
          changeCurrentTabIndexTo={currentTab}
        />
      </Grid>

      <Grid
        item
        xs={12}
        container
        flexDirection="row"
        alignItems={"flex-end"}
        alignContent={"flex-end"}
        justifyContent={"flex-end"}
      >
        {recordExists && (
          <Typography sx={{ color: "error.main", pb: 1 }}>
            The patient has already received an injection of the same drug in
            the same eye today. Only 1 treatment can be saved per eye per day
            for each patient.
          </Typography>
        )}
        <SecondaryButton
          onClick={handleCancelClick}
          disabled={formState.isSubmitting}
          sx={{ mr: 2 }}
        >
          {t("singlePharse.cancel")}
        </SecondaryButton>

        <BaseButton
          onClick={
            mode === "add" && (currentTab === 0 || currentTab === 1)
              ? () =>
                  setCurrentTab((val) => {
                    ++val;
                    return val;
                  })
              : handleSubmit(
                  mode === "add" ? onSubmit : onSubmit2,
                  handleSubmitError
                )
          }
          disabled={
            formState.isSubmitting ||
            !_.isEmpty(formState.errors) ||
            recordExists ||
            serialNumberStatus.isused ||
            serialNumberStatus.isNotExist
          }
          type="submit"
        >
          {mode === "add" && (currentTab === 0 || currentTab === 1)
            ? t("singlePharse.next")
            : t("singlePharse.save")}
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default TreatmentInfoForm;
