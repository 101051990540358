import { Box, Typography } from "@mui/material";

interface LastTreatmentBasicInfoItemProps {
  icon: React.ReactNode;
  label: string;
  value: string | number | null;  // Added null to resolve error in LastTreatmentInfo.tsx 
}

const LastTreatmentBasicInfoItem = (props: LastTreatmentBasicInfoItemProps) => {
  const { label, value, icon } = props;

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start", gap: 0.5 }}>
      {icon}
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          {label}
        </Typography>
        <Typography variant="subtitle1" fontWeight="bold">
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default LastTreatmentBasicInfoItem;
