import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  Grid,
  Chip,
  Tooltip,
} from "@mui/material";
import { useQueries, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPatientsData } from "../../../services/patient";
import { PatientsProps } from "../../../utils/types/services/patients";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import PatientListOptionBar from "./PatientListOptionBar";
import PatientListOptionBarMobile from "./PatientListOptionBarMobile";
import PatientListDetailsMobile from "./PatientListDetailsMobile";
import PatientListPageHeader from "./PatientListPageHeader";
import TablePaginationActions from "../../shareComponents/table/CustomTablePagination";
import ReactGA from "react-ga4";
import {
  CategoryType,
  NavigationActionType,
} from "../../../utils/constants/ga";
import { ROUTE_PATHS } from "../../../utils/constants/routes/webPaths";
import { userGetData } from "../../../services/user";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import PspIndicator from "../../PspIndicator";
import convertToTimezone from "../../../utils/helperFunction/convertTimeZone";

const buttonStyle = {
  color: "#000000",
  borderRadius: "8px",
  padding: "8px 20px",
  fontSize: "14px",
  fontWeight: "400",
  border: "none",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",
} as const;

const TABLE_HEADERS = [
  "singlePharse.no",
  "singlePharse.firstName",
  "singlePharse.lastName",
  "singlePharse.recognitionId",
  "singlePharse.patientId",
  "singlePharse.gender",
  "singlePharse.diseaseType",
  "singlePharse.lastTreatment",
  "singlePharse.careGiver?",
  "singlePharse.owner",
  "singlePharse.organization",
  "singlePharse.signedAppConsent?",
  "singlePharse.emptyString",
  "singlePharse.emptyString2",
] as const;

interface PatientListOptionBarProps {
  handleAddPatientClick: () => void;
  handleShareClick: (pId: number, hcpId: number) => void;
}

const PatientsTable = (props: PatientListOptionBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleAddPatientClick, handleShareClick } = props;
  const fixedSearch = useRef<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [oKClick, setOKClick] = useState(false);
  const { data } = useQuery([REACT_QUERY_KEYS.USER_DATA], userGetData);
  const userId = data?.data?.userId;
  const pspIndicator = data?.data?.joinedPsp;

  const NUM_10 = 10;
  const NUM_53 = 53;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (fixedSearch.current != null) {
      // 👉️ TypeScript knows that ref is not null here
      setHeaderHeight(fixedSearch?.current.clientHeight);
    }
  }, [fixedSearch, headerHeight, oKClick]);

  const [{ data: patientsData }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.PATIENTS],
        queryFn: getPatientsData,
        retry: true,
        refetchOnMount: "always",
      },
    ],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(NUM_10);

  const [selectedDiseaseType, setSelectedDiseaseType] = useState(-1);
  const [searchText, setSearchText] = useState("");

  const filteredPatientsData = useMemo(() => {
    if (patientsData?.success === false) return [];

    let patientsDataCopy = _.cloneDeep(patientsData?.data ?? []);

    if (selectedDiseaseType !== -1) {
      patientsDataCopy = patientsDataCopy.filter(
        (patient) =>
          patient.diseaseTypeOS === selectedDiseaseType ||
          patient.diseaseTypeOD === selectedDiseaseType
      );
    }

    if (searchText !== "") {
      const lowercaseSearchText = searchText
        .trim()
        .toLowerCase()
        .replace(/ +(?= )/g, "");

      patientsDataCopy = patientsDataCopy.filter(
        (patient) =>
          (patient.diseaseTypeNameOD ?? "")
            .toLowerCase()
            .includes(lowercaseSearchText) ||
          (patient.diseaseTypeNameOS ?? "")
            .toLowerCase()
            .includes(lowercaseSearchText) ||
          patient.id.toString().includes(lowercaseSearchText) ||
          patient.gender.toLowerCase().includes(lowercaseSearchText) ||
          (
            (patient.firstName?.toLowerCase() ?? "") +
            " " +
            (patient.lastName?.toLowerCase() ?? "")
          ).includes(lowercaseSearchText) ||
          (patient.recognitionId ?? "")
            .toLowerCase()
            .includes(lowercaseSearchText)
      );
    }

    return patientsDataCopy;
  }, [patientsData, selectedDiseaseType, searchText]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(() => {
    return page > 0
      ? Math.min(
          Math.max(0, (1 + page) * rowsPerPage - filteredPatientsData.length),
          NUM_10
        )
      : 0;
  }, [page, rowsPerPage, filteredPatientsData]);

  useEffect(() => {
    if (page >= Math.ceil(filteredPatientsData.length / rowsPerPage))
      setPage(0);
  }, [filteredPatientsData]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const renderDataRow = useCallback(
    (row: PatientsProps, index: number) => {
      const {
        firstName,
        lastName,
        recognitionId,
        id,
        gender,
        diseaseTypeNameOS,
        diseaseTypeNameOD,
        lastTreatmentDate,
        owner,
        organization,
        hcp,
        cgMob1,
        cgMob2,
        hasConsent,
      } = row;

      const handlePatientNameClick = () => {
        navigate(ROUTE_PATHS.PATIENT_DETAILS.replace(":id", id.toString()));
        ReactGA.event({
          category: CategoryType.Navigation,
          action: NavigationActionType.Patients_ViewDetails,
          value: id,
        });
      };

      const nameCellHoverSx = {
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "action.hover",
        },
      };

      const seperator =
        diseaseTypeNameOS !== null && diseaseTypeNameOD !== null ? "," : "";

      const treatmentDate =
        lastTreatmentDate && dayjs(lastTreatmentDate).isValid()
          ? convertToTimezone({utcDate: lastTreatmentDate, targetTimezone: "Asia/Hong_Kong", format: "DD MMM YYYY"})
          : null;

      return (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            {page * rowsPerPage + index + 1}
          </TableCell>
          <TableCell sx={nameCellHoverSx} onClick={handlePatientNameClick}>
            <Typography variant="subtitle2" fontWeight="bold">
              {firstName}
            </Typography>
          </TableCell>
          <TableCell sx={nameCellHoverSx} onClick={handlePatientNameClick}>
            <Typography variant="subtitle2" fontWeight="bold">
              {lastName}
            </Typography>
          </TableCell>
          <TableCell>{recognitionId}</TableCell>
          <TableCell>{id}</TableCell>
          <TableCell>{t(`singlePharse.${gender}`)}</TableCell>
          <TableCell>
            {diseaseTypeNameOS}
            {seperator}
            {diseaseTypeNameOD}
          </TableCell>
          <TableCell>{treatmentDate}</TableCell>
          <TableCell>
            {(cgMob1 !== null || cgMob2 !== null) && (
              <Tooltip
                title="To view the caregiver information, please click 'View Details' button"
                key={index}
                placement="right"
              >
                <Chip
                  label="Yes"
                  sx={{
                    backgroundColor: "#87D4C4",
                    fontSize: "14px",
                  }}
                />
              </Tooltip>
            )}
            {cgMob1 === null && cgMob2 === null && (
              <Chip
                label="No"
                sx={{
                  backgroundColor: "#C2BAB5",
                  fontSize: "14px",
                }}
              />
            )}
          </TableCell>
          <TableCell>
            <Chip
              label={owner}
              sx={{
                backgroundColor: userId === hcp ? "#FFBD69" : "#87D4C4",
                fontSize: "14px",
              }}
            />
          </TableCell>
          <TableCell>{organization}</TableCell>
          <TableCell>
            {hasConsent ? (
              <Chip
                label="Yes"
                sx={{
                  backgroundColor: "#87D4C4",

                  fontSize: "14px",
                }}
              />
            ) : (
              <Chip
                label="No"
                sx={{
                  backgroundColor: "#C2BAB5",

                  fontSize: "14px",
                }}
              />
            )}
          </TableCell>
          <TableCell sx={{ px: 0 }}>
            {userId === hcp && (
              <SecondaryButton
                onClick={() => handleShareClick(id, hcp)}
                sx={buttonStyle}
              >
                Share Profile
              </SecondaryButton>
            )}
          </TableCell>
          <TableCell sx={{ px: 0 }}>
            <SecondaryButton onClick={handlePatientNameClick} sx={buttonStyle}>
              View Details
            </SecondaryButton>
          </TableCell>
        </TableRow>
      );
    },
    [page, rowsPerPage, t]
  );

  const handleOKClick = () => {
    setOKClick(true);
  };

  return (
    <Grid item>
      <Grid
        item
        md
        sm
        sx={{ display: { xs: "none", sm: "block", md: "block" } }}
      >
        {(pspIndicator === null || pspIndicator === false) && (
          <Grid item xs={12} sx={{ pb: 4, backgroundColor: "#F5F6FA" }}>
            <PspIndicator pspIndicator={pspIndicator}></PspIndicator>
          </Grid>
        )}
        <TableContainer component={Paper}>
          <Grid item>
            <PatientListPageHeader setSearchText={setSearchText} />

            <Divider sx={{ mb: 1 }} />
            <PatientListOptionBar
              selectedDiseaseType={selectedDiseaseType}
              setSelectedDiseaseType={setSelectedDiseaseType}
              handleAddPatientClick={handleAddPatientClick}
            />
            <Divider sx={{ my: 1 }} />
          </Grid>
          <Grid item>
            <Box sx={{ overflow: "auto" }}>
              <Box
                sx={{ width: "100%", display: "table", tableLayout: "fixed" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="patients table">
                  <TableHead>
                    <TableRow>
                      {TABLE_HEADERS.map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            alignItems: "center",
                            justifyItems: "center",
                            py: 0,
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            {t(header)}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? filteredPatientsData.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : filteredPatientsData
                    ).map(renderDataRow)}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: NUM_53 * emptyRows }}>
                        <TableCell colSpan={TABLE_HEADERS.length} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[rowsPerPage]}
                        colSpan={TABLE_HEADERS.length}
                        count={filteredPatientsData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": t("singlePharse.rowsPerPage"),
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Box>
            </Box>
          </Grid>
        </TableContainer>
      </Grid>
      <Grid
        item
        md
        sm
        sx={{ display: { xs: "block", sm: "none", md: "none" } }}
      >
        <TableContainer component={Paper}>
          <Grid
            item
            xs
            ref={fixedSearch}
            sx={{
              position: "fixed",
              background: "#FFFFFF",
              zIndex: "99",
              width: "calc(100% - 36px)",
            }}
          >
            {(pspIndicator === null || pspIndicator === false) && (
              <Grid item xs={12}>
                <PspIndicator
                  pspIndicator={pspIndicator}
                  handleOKClick={handleOKClick}
                ></PspIndicator>
              </Grid>
            )}

            <PatientListPageHeader setSearchText={setSearchText} />

            <PatientListOptionBarMobile
              selectedDiseaseType={selectedDiseaseType}
              setSelectedDiseaseType={setSelectedDiseaseType}
              handleAddPatientClick={handleAddPatientClick}
            />
            <Divider
              sx={{ my: 1, boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.25)" }}
            />
          </Grid>
          <Grid
            item
            xs
            sx={{
              paddingTop: headerHeight + "px",
            }}
          >
            <PatientListDetailsMobile
              filteredData={filteredPatientsData}
              handleShareClick={handleShareClick}
            ></PatientListDetailsMobile>
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PatientsTable;
