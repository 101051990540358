import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseButton from "../../shareComponents/button/BaseButton";
import { useFormContext, SubmitErrorHandler } from "react-hook-form";
import { useCallback } from "react";
import { useQuery, useQueries, useQueryClient } from "@tanstack/react-query";
import { userEditData } from "../../../services/user";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import { getAllDBConstants } from "../../../services/dbConstants";
import useModal from "../../../hooks/useModal";
import ConfirmPasswordModal from "./ConfirmPasswordModal";
import { UserFormState } from ".";
import ReactGA from "react-ga4";
import { CategoryType, FormType } from "../../../utils/constants/ga";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import { mobileNumberPrefix } from "../../../utils/constants/general";

interface ConfirmButtonProps {
  resetFormInfo: () => void;
}

const ConfirmButton = (props: ConfirmButtonProps) => {
  const { resetFormInfo } = props;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const STATUS_401 = 401;

  const { handleSubmit, getValues, reset, formState, setValue } =
    useFormContext<UserFormState>();

  const { data, refetch } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    () => {
      const [
        firstName,
        lastName,
        nickName,
        instituteDetails,
        mobileLocal,
        mobilePrefix,
        password,
        email,
        sector
      ] = getValues([
        "firstName",
        "lastName",
        "nickName",
        "instituteDetails",
        "mobileLocal",
        "mobilePrefix",
        "password",
        "email",
        "sector"
      ]);
      return userEditData({
        firstName,
        lastName,
        nickName,
        mobile: mobilePrefix + mobileLocal,
        password,
        instituteDetails,
        email,
        sector
      });
    },
    {
      structuralSharing: (oldData, newData) => {
        if (
          oldData === undefined ||
          newData.success ||
          newData.status === STATUS_401
        )
          return newData;
        if (!newData.success) {
          return {
            success: newData.success,
            status: oldData.status,
            data: oldData.data,
          };
        }
        return newData;
      },
    }
  );

  const [{ data: dbConstants }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.DB_CONSTANTS],
        queryFn: getAllDBConstants,
      },
    ],
  });

  const instituteNames = dbConstants?.data?.instituteNames ?? [];

  const {
    isOpen: isPasswordModalOpen,
    closeModal: closePasswordModal,
    openModal: openPasswordModal,
  } = useModal();

  const onSubmit = useCallback(
    async (e_data: UserFormState) => {
      const dirtyFields = formState.dirtyFields;
      if (
        (dirtyFields["mobileLocal"] === true ||
          dirtyFields["mobilePrefix"] === true) &&
        e_data.password === ""
      ) {
        openPasswordModal();
        return;
      }

      const refetchResult = await refetch();
      if (e_data.password !== "") closePasswordModal();
      if (refetchResult.data?.success) {
        reset({
          firstName: refetchResult.data?.data?.firstName ?? "",
          lastName: refetchResult.data?.data?.lastName ?? "",
          nickName: refetchResult.data?.data?.nickName ?? "",
          email: refetchResult.data?.data?.email ?? "",
          mobileLocal: (refetchResult.data.data?.mobile ?? "").substring(
            mobileNumberPrefix.length
          ),
          mobilePrefix: (refetchResult.data.data?.mobile ?? "").substring(
            0,
            mobileNumberPrefix.length
          ),
          password: "",
        });
      } else {
        setValue("password", "");
        if (refetchResult.data?.status === STATUS_401) {
          queryClient.setQueryData([REACT_QUERY_KEYS.USER_DATA], {
            success: false,
            status: 401,
          });
        }
      }

      ReactGA.event({
        category: CategoryType.FormSubmit,
        action: FormType.UpdateUserProfileForm,
        value: 200,
      });
    },
    [
      refetch,
      setValue,
      reset,
      formState.dirtyFields,
      instituteNames,
      openPasswordModal,
      closePasswordModal,
    ]
  );

  const handleSubmitError: SubmitErrorHandler<UserFormState> = useCallback(
    (error) => {
      const fields = Object.keys(error);
      ReactGA.event({
        category: `${CategoryType.FormValidationError}_Other_${FormType.UpdateUserProfileForm}`,
        action: FormType.UpdateUserProfileForm,
        label: `${fields.flatMap((e) => e)}`,
        nonInteraction: false,
        value: 400,
      });
    },
    []
  );

  const resetForm = useCallback(() => {
    resetFormInfo();
  }, [reset, data, instituteNames]);

  const handleClosePasswordModal = () => {
    setValue("password", "");
    closePasswordModal();
  };

  return (
    <Grid
      container
      justifyContent={"flex-end"}
      direction={"row"}
      alignItems={"center"}
    >
      <ConfirmPasswordModal
        isOpen={isPasswordModalOpen}
        toggleModal={handleClosePasswordModal}
        submitWithPassword={handleSubmit(onSubmit, handleSubmitError)}
      />
      <SecondaryButton
        disabled={formState.isSubmitting}
        type="reset"
        onClick={resetForm}
        sx={{ margin: "1%", minWidth: "7vw" }}
      >
        {t("singlePharse.cancel")}
      </SecondaryButton>
      <BaseButton
        disabled={formState.isSubmitting}
        type="submit"
        onClick={handleSubmit(onSubmit, handleSubmitError)}
        sx={{ margin: "1%", minWidth: "7vw" }}
      >
        {t("singlePharse.save")}
      </BaseButton>
    </Grid>
  );
};

export default ConfirmButton;
