import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import {
  Divider,
  Grid,
  MenuItem,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAllDBConstants } from "../../../services/dbConstants";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import BaseInput from "../../shareComponents/input/BaseInput";
import LastTreatmentBasicInfoItem from "./LastTreatmentBasicInfoItem";
import { useTranslation } from "react-i18next";
import {
  EyeDetailsProps,
  TreatmentRecord,
} from "../../../utils/types/services/patients";
import { useMemo, useCallback, ChangeEvent, Fragment, useState } from "react";
import dayjs from "dayjs";
import { updateFixedTreatmentWeeks } from "../../../services/patient";
import convertToTimezone from "../../../utils/helperFunction/convertTimeZone";

interface NextTreatmentRecommendationProps {
  eyeDetails: EyeDetailsProps;
  treatmentMethod: number;
  handleChangeTreatmentMethods: (value: number) => void;
  record?: TreatmentRecord;
  isOwner: boolean;
}

const NextTreatmentRecommendation = (
  props: NextTreatmentRecommendationProps
) => {
  const {
    eyeDetails,
    treatmentMethod,
    handleChangeTreatmentMethods,
    record,
    isOwner,
  } = props;
  const { t } = useTranslation();
  const { mutateAsync } = useMutation(updateFixedTreatmentWeeks);

  const { data: dbConstants } = useQuery(
    [REACT_QUERY_KEYS.DB_CONSTANTS],
    getAllDBConstants
  );

  const treatmentPhaseTypes = dbConstants?.data?.treatmentPhaseTypes ?? [];
  const fixedTreatmentWeekLists =
    dbConstants?.data?.fixedTreatmentWeekLists ?? [];

  const [fixedTreatmentValue, setFixedTreatmentValue] = useState<number | null>(
    eyeDetails.fixedWeeks
  );

  const onChangeFixedTreatmentValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFixedTreatmentValue(+event.target.value);
      mutateAsync({ eyeId: eyeDetails.id, fixedWeeks: +event.target.value });
    },
    [mutateAsync, eyeDetails.id]
  );

  const onChangeMethod = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChangeTreatmentMethods(+event.target.value);
    },
    [handleChangeTreatmentMethods]
  );

  const [suggestInterval, suggestDate, hints] = useMemo(() => {
    const findResult = eyeDetails.recommendation.find(
      (e) => e.basedOn === treatmentMethod
    );

    const interval = findResult?.suggestInterval ?? "-";
    const hints_result = findResult?.hints ?? "-";
    const date = findResult?.suggestDate;
    const dataAdj =
      !date || !dayjs(date).isValid()
        ? date ?? "-"
        : convertToTimezone({utcDate: date, targetTimezone: "Asia/Hong_Kong", format: "DD MMM YYYY"});
    return [interval, dataAdj, hints_result];
  }, [eyeDetails.recommendation, treatmentMethod]);

  const [fixedTreatmentUI, fixedWeeks] = useMemo(() => {
    const isFixed =
      treatmentMethod ===
      treatmentPhaseTypes.find((e) => e.displayText.toLowerCase() === "fixed")
        ?.id;
    if (!isFixed) return [false, null];

    const findResult = eyeDetails.recommendation.find(
      (e) => e.basedOn === treatmentMethod
    );

    if (
      findResult === undefined ||
      findResult.suggestInterval !== null ||
      findResult.suggestDate !== null ||
      findResult.hints !== null
    )
      return [false, null];

    const filtered_fixedWeeks = fixedTreatmentWeekLists.filter(
      (e) =>
        e.drugId === record?.drugId && e.diseaseTypeId === record.diseaseTypeId
    );
    return [filtered_fixedWeeks.length > 0, filtered_fixedWeeks];
  }, [
    treatmentMethod,
    treatmentPhaseTypes,
    fixedTreatmentWeekLists,
    eyeDetails.recommendation,
    record,
  ]);

  return (
    <Grid
      item
      xs={12}
      sx={{ m: 1, p: 1, backgroundColor: "#ECDFF0", height: "100%" }}
    >
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          align="center"
          fontWeight="bold"
          sx={{ mb: 2 }}
        >
          {t("patientDetailPage.nextTreatmentRecommendation")}
        </Typography>
      </Grid>

      <Grid container item xs={12} alignItems="center" justifyContent="center">
        <BaseInput
          select
          label={t("patientDetailPage.basedOn")}
          onChange={onChangeMethod}
          value={treatmentMethod}
          sx={{ m: 0, p: 0, minWidth: "50%" }}
          disabled={!isOwner}
        >
          {treatmentPhaseTypes.map((e) => (
            <MenuItem key={e.id} value={e.id}>
              {e.displayText}
            </MenuItem>
          ))}
        </BaseInput>
      </Grid>

      <Divider sx={{ my: 1 }} />

      <Grid item xs={12} alignItems="center" justifyContent="center">
        {fixedTreatmentUI ? (
          <Fragment>
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 0.5 }}>
              <AccessTimeOutlinedIcon sx={{ color: "#C69ED3" }} />
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  {t("patientDetailPage.suggestedInterval")}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={fixedTreatmentValue}
                      name="radio-buttons-group"
                      onChange={onChangeFixedTreatmentValue}
                    >
                      {fixedWeeks?.map((e) => (
                        <FormControlLabel
                          key={e.id}
                          value={e.fixedWeek}
                          control={<Radio />}
                          label={`Q${e.fixedWeek}W`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 0.5 }}>
              <CalendarMonthOutlinedIcon sx={{ color: "#C69ED3" }} />
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  {t("patientDetailPage.suggestedDate")}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold">
                  {fixedWeeks?.find((e) => e.fixedWeek === fixedTreatmentValue)
                    ?.fixedWeek && record !== undefined
                    ? dayjs(record.date)
                        .add(fixedTreatmentValue!, "weeks")
                        .format("DD MMM YYYY")
                    : t("patientDetailPage.selectTreatmentInterval")}
                </Typography>
              </Box>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <LastTreatmentBasicInfoItem
              label={t("patientDetailPage.suggestedInterval")}
              value={suggestInterval}
              icon={<AccessTimeOutlinedIcon sx={{ color: "#C69ED3" }} />}
            />

            <LastTreatmentBasicInfoItem
              label={t("patientDetailPage.suggestedDate")}
              value={suggestDate}
              icon={<CalendarMonthOutlinedIcon sx={{ color: "#C69ED3" }} />}
            />
          </Fragment>
        )}

        <LastTreatmentBasicInfoItem
          label={t("patientDetailPage.hints")}
          value={hints}
          icon={<TipsAndUpdatesOutlinedIcon sx={{ color: "#C69ED3" }} />}
        />
      </Grid>
    </Grid>
  );
};

export default NextTreatmentRecommendation;
