import { Box, Typography, Chip, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import {
  CategoryType,
  NavigationActionType,
} from "../../../utils/constants/ga";
import dayjs from "dayjs";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import { ROUTE_PATHS } from "../../../utils/constants/routes/webPaths";
import { useNavigate } from "react-router-dom";
import { userGetData } from "../../../services/user";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import { PatientsProps } from "../../../utils/types/services/patients";
import convertToTimezone from "../../../utils/helperFunction/convertTimeZone";

const itemStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2px 8px",
  mb: 1,
} as const;

const listHeader = {
  color: "#737373",
  fontSize: "14px",
  fontWeight: "400",
  width: "40%",
} as const;

const listValue = {
  fontSize: "16px",
  fontWeight: "500",
  textAlign: "right",
  width: "60%",
  wordBreak: "break-all",
} as const;

const buttonStyle = {
  color: "#000000",
  borderRadius: "8px",
  padding: "8px 20px",
  fontSize: "14px",
  fontWeight: "400",
  border: "none",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",
} as const;

interface PatientListDetailsMobileProps {
  filteredData: PatientsProps[];
  handleShareClick: (pId: number, hcpId: number) => void;
}

const PatientListDetailsMobile = (props: PatientListDetailsMobileProps) => {
  const { filteredData, handleShareClick } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useQuery([REACT_QUERY_KEYS.USER_DATA], userGetData);
  const userId = data?.data?.userId;

  const renderlistItems = useCallback(
    (row: PatientsProps, index: number) => {
      const {
        id,
        firstName,
        lastName,
        recognitionId,
        gender,
        diseaseTypeNameOS,
        diseaseTypeNameOD,
        lastTreatmentDate,
        hcp,
        owner,
        organization,
        cgMob1,
        cgMob2,
        hasConsent,
      } = row;
      const seperator =
        diseaseTypeNameOS !== null && diseaseTypeNameOD !== null ? "," : "";

      const handleDetailsClick = () => {
        navigate(ROUTE_PATHS.PATIENT_DETAILS.replace(":id", id.toString()));
        ReactGA.event({
          category: CategoryType.Navigation,
          action: NavigationActionType.Patients_ViewDetails,
          value: id,
        });
      };

      const dateDisplay =
        lastTreatmentDate && dayjs(lastTreatmentDate).isValid()
          ? convertToTimezone({utcDate: lastTreatmentDate, targetTimezone: "Asia/Hong_Kong", format: "DD MMM YYYY"})
          : null;
      return (
        <Box
          key={id}
          sx={{ padding: "16px", borderBottom: "1px solid #E0E0E0" }}
        >
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>
              {t("singlePharse.firstName")}
            </Typography>
            <Typography sx={listValue}>{firstName}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>
              {t("singlePharse.lastName")}
            </Typography>
            <Typography sx={listValue}>{lastName}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>
              {t("singlePharse.recognitionId")}
            </Typography>
            <Typography sx={listValue}>{recognitionId}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>
              {t("singlePharse.patientId")}
            </Typography>
            <Typography sx={listValue}>{id}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>{t("singlePharse.gender")}</Typography>
            <Typography sx={listValue}>{gender}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>
              {t("singlePharse.diseaseType")}
            </Typography>
            <Typography sx={{ ...listValue, wordBreak: "break-all" }}>
              {diseaseTypeNameOS}
              {seperator}
              {diseaseTypeNameOD}
            </Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>
              {t("singlePharse.lastTreatment")}
            </Typography>
            <Typography sx={listValue}>{dateDisplay}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>
              {t("singlePharse.careGiver?")}
            </Typography>
            {(cgMob1 !== null || cgMob2 !== null) && (
              <Tooltip
                title="To view the caregiver information, please click 'View Details' button"
                key={index}
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                placement="left"
              >
                <Chip
                  label="Yes"
                  sx={{
                    backgroundColor: "#87D4C4",
                    fontSize: "14px",
                  }}
                />
              </Tooltip>
            )}
            {cgMob1 === null && cgMob2 === null && (
              <Chip
                label="No"
                sx={{
                  backgroundColor: "#C2BAB5",
                  fontSize: "14px",
                }}
              />
            )}
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>{t("singlePharse.owner")}</Typography>
            <Chip
              label={owner}
              sx={{
                backgroundColor: userId === hcp ? "#FFBD69" : "#87D4C4",
                fontSize: "14px",
              }}
            />
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>
              {t("singlePharse.signedAppConsent?")}
            </Typography>

            {hasConsent ? (
              <Chip
                label="Yes"
                sx={{
                  backgroundColor: "#87D4C4",

                  fontSize: "14px",
                }}
              />
            ) : (
              <Chip
                label="No"
                sx={{
                  backgroundColor: "#C2BAB5",

                  fontSize: "14px",
                }}
              />
            )}
          </Box>

          <Box sx={itemStyle}>
            <Typography sx={listHeader}>
              {t("singlePharse.organization")}
            </Typography>
            <Typography sx={listValue}>{organization}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              mt: 5,
              px: 2,
            }}
          >
            {userId === hcp && (
              <SecondaryButton
                onClick={() => handleShareClick(id, hcp)}
                size="small"
                sx={buttonStyle}
              >
                Share Profile
              </SecondaryButton>
            )}
            <SecondaryButton
              onClick={handleDetailsClick}
              size="small"
              sx={buttonStyle}
            >
              View Details
            </SecondaryButton>
          </Box>
        </Box>
      );
    },
    [t]
  );
  return (
    <Box>
      {filteredData.length ? (
        filteredData.map(renderlistItems)
      ) : (
        <Typography variant="subtitle1" sx={{ m: 5, textAlign: "center" }}>
          No data found
        </Typography>
      )}
    </Box>
  );
};

export default PatientListDetailsMobile;
