import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import FormInput from "../../shareComponents/form/FormInput";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import NoneDisplayInput from "../../shareComponents/input/NonDisplayForwardRefInput";
import { UserFormState } from ".";
import { mobileNumberPrefix } from "../../../utils/constants/general";
import { mobileValidate } from "../../../services/itadmin";
import { useState } from "react";
import { Stack } from "@mui/system";
import { ChangeNameSectorModal } from "../../shareComponents/modal/ChangeNameSectorModal";

const listHeader = {
  color: "#737373",
  fontSize: "14px",
  fontWeight: "400",
} as const;

const listValue = {
  fontSize: "16px",
  fontWeight: "500",
  paddingBottom: "2px",
} as const;

const PersonalInfo = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { control, watch, getValues, setValue} = useFormContext<UserFormState>();
  const MOBILE_PHRASE = "singlePharse.mobile";

  const handleMobileBlur = async () => {
    const enteredMobile = getValues('mobilePrefix')+getValues("mobileLocal");
    try {
      const response = await mobileValidate(enteredMobile,getValues('email'));
      if (response.mobile === "User with mobile exists") {
        setValue("isMobileExists", true);
        setValue("mobileLocal", enteredMobile.substring(4) ?? "", {
          shouldValidate: true,
        });
      } else {
        setValue("isMobileExists", false);
        setValue("mobileLocal", enteredMobile.substring(4) ?? "", {
          shouldValidate: true,
        });
      }
    } catch (error) {}
  };

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      direction={"row"}
      alignItems={"center"}
      mb={2}
    >
      <Grid item xs={12} sm={12} md={6} sx={{ paddingX: "1%" }}>
        <FormInput
          disabled
          control={control}
          name="email"
          label={t("singlePharse.email")}
          fullWidth
          autoComplete="username"
          type="email"
        />
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ paddingX: "1%" }}
        flexDirection="row"
      >
        <PhoneInputWithCountry
          control={control}
          name="mobilePrefix"
          label={t(MOBILE_PHRASE)}
          international
          addInternationalOption={false}
          countries={["HK", "MO"]}
          defaultCountry={
            watch("mobilePrefix") === mobileNumberPrefix ? "HK" : "MO"
          }
          placeholder={t(MOBILE_PHRASE)}
          inputComponent={NoneDisplayInput}
        />
        <FormInput
          control={control}
          name="mobileLocal"
          label={t(MOBILE_PHRASE)}
          variant="outlined"
          sx={{ flexGrow: 1 }}
          type="tel-local"
          onBlur={handleMobileBlur}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
          {t("singlePharse.personalInfo")}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={6} mt={2}>
          <Typography variant="subtitle2" sx={listHeader} align="left">
            {t("singlePharse.firstName")}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="subtitle1" sx={listValue} align="left">
                {getValues("firstName")}
              </Typography>
              <Button
                variant="text"
                size="medium"
                onClick={() => setOpen(true)}
              >
            {t("singlePharse.changeMyName")}
            </Button>
          </Stack>
      </Grid>

      <Grid item xs={12} sm={12} md={6} mt={2}>
          <Typography variant="subtitle2" sx={listHeader} align="left">
              {t("singlePharse.lastName")}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="subtitle1" sx={listValue} align="left">
                {getValues("lastName")}
            </Typography>
            <Button
                variant="text"
                size="medium"
                onClick={() => setOpen(true)}
            >
            {t("singlePharse.changeMyName")}
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12} mt={2}>
            <Typography variant="subtitle2" sx={listHeader} align="left">
                {t("singlePharse.sector")}
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="subtitle1" sx={listValue} align="left">
                  {getValues("sector")}
              </Typography>
              <Button
                variant="text"
                size="medium"
                onClick={() => setOpen(true)}
              >
            {t("singlePharse.changeMySector")}
            </Button>
            </Stack>
         </Grid>

      <ChangeNameSectorModal
          open={open}
          handleClose={() => setOpen(false)}
      />
    </Grid>
  );
};

export default PersonalInfo;
