import { Dialog, DialogContent, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";


export const ChangeNameSectorModal = ({ open, handleClose }: {open: boolean, handleClose: () => void}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: 0,
          maxWidth: 600,
          width: '100%',
          margin: { xs: 2, sm: 'auto' }
        }
      }}
    >
      <DialogContent 
        sx={{ 
          textAlign: 'left', 
          p: { xs: 2, sm: 3 }
        }}
      >
        <Typography variant="body1" sx={{ 
          mb: { xs: 1.5, sm: 2 }, 
          color: 'black', 
          fontWeight: 'bold',
          fontSize: { xs: '15px', sm: '16px', md: '18px' } 
        }}>
          {t("ChangeNameSectorModal.heading")}
        </Typography>
        
        <Typography 
          variant="body2" 
          sx={{ 
            mb: { xs: 1.5, sm: 2 },
            fontSize: { xs: '13px', sm: '14px' }
          }}
        >
          {t("ChangeNameSectorModal.body")}
        </Typography>
        
        <Button 
          variant="contained" 
          color="primary"  
          onClick={handleClose}
          sx={{ 
            textTransform: 'none',
            px: { xs: 1.5, sm: 2 },
            py: { xs: 0.5, sm: 0.75 },
            fontSize: { xs: '13px', sm: '14px' }
          }}
        >
          {t("singlePharse.OK")}
        </Button>
      </DialogContent>
    </Dialog>
  );
};