import AddIcon from "@mui/icons-material/Add";
import ImageIcon from "@mui/icons-material/Image";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Typography, Grid, IconButton, Collapse } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import * as React from "react";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import BaseButton from "../../shareComponents/button/BaseButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import convertToTimezone from "../../../utils/helperFunction/convertTimeZone";

const HEADER_INFO_SX = {
  backgroundColor: "primary.light",
  px: 1.5,
  py: 1,
  borderRadius: 10,
  ml: 1,
} as const;

interface TreatmentRecordHeaderMobileProps {
  firstInjectionDate: string | null;
  totalInjections: number;
  handleAddTreatmentRecordClick: () => void;
}

const TreatmentRecordHeaderMobile = (
  props: TreatmentRecordHeaderMobileProps
) => {
  const { t } = useTranslation();
  const { firstInjectionDate, totalInjections, handleAddTreatmentRecordClick } =
    props;
  const [showinfo, setShowinfo] = React.useState<boolean>(false);

  const handleCollapseButtonClick = () => {
    setShowinfo(!showinfo);
  };

  return (
    <Box>
      <Box
        sx={{
          pt: 2,
          pb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          {t("patientDetailPage.treatmentRecords")}
        </Typography>
        <IconButton onClick={handleCollapseButtonClick}>
          {!showinfo ? (
            <KeyboardArrowDownIcon color={"primary"} />
          ) : (
            <KeyboardArrowUpIcon />
          )}
        </IconButton>
      </Box>
      <Collapse in={showinfo}>
        <Box sx={{ pb: 1.5 }}>
          <Typography variant="subtitle2" display="inline" color="primary.main">
            {t("patientDetailPage.firstInjection")}
          </Typography>
          <Typography
            variant="subtitle1"
            display="inline"
            color="primary.main"
            fontWeight="bold"
            sx={HEADER_INFO_SX}
          >
            {firstInjectionDate
              ? convertToTimezone({utcDate: firstInjectionDate, targetTimezone: "Asia/Hong_Kong", format: "DD MMM YYYY"})
              : "N/A"}
          </Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography variant="subtitle2" display="inline" color="primary.main">
            {t("patientDetailPage.totalInjections")}
          </Typography>
          <Typography
            variant="subtitle1"
            display="inline"
            color="primary.main"
            fontWeight="bold"
            sx={HEADER_INFO_SX}
          >
            {totalInjections}
          </Typography>
        </Box>
      </Collapse>
      <Box sx={{ position: "fixed", zIndex: 99, right: "9vw", bottom: "5vh" }}>
        <BaseButton
          size="large"
          startIcon={<AddIcon />}
          onClick={handleAddTreatmentRecordClick}
        >
          Record
        </BaseButton>
      </Box>
    </Box>
  );
};

export default TreatmentRecordHeaderMobile;
