import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import VaccinesOutlinedIcon from "@mui/icons-material/VaccinesOutlined";
import { Divider, Grid, Typography } from "@mui/material";
import { TreatmentRecord } from "../../../utils/types/services/patients";
import CSTAndVAStatusItem from "./CSTAndVAStatusItem";
import LastTreatmentBasicInfoItem from "./LastTreatmentBasicInfoItem";
import LastTreatmentOtherInfo from "./LastTreatmentOtherInfo";
import { useTranslation } from "react-i18next";
import convertToTimezone from "../../../utils/helperFunction/convertTimeZone";

interface LastTreatmentInfoProps {
  record?: TreatmentRecord;
}

const LastTreatmentInfo = (props: LastTreatmentInfoProps) => {
  const { t } = useTranslation();

  const treatmentInterval = props.record?.treatmentInterval || "-";
  const date = props.record?.date
    ? convertToTimezone({utcDate: props.record?.date, targetTimezone: "Asia/Hong_Kong", format: "DD MMM YYYY"})
    : "-";
  const drugName = props.record?.drugName ?? "-";
  const cst = props.record?.latestCst ?? "-";
  const cstStatus = props.record?.cstStatus ?? null;
  const va =
    props.record?.latestVa === null || props.record?.latestVa === undefined
      ? "-"
      : props.record?.latestVa === 0
      ? props.record?.latestLetterScoreDesc
      : props.record?.latestVa;
  const vaStatus = props.record?.vaStatus ?? null;
  const heme = props.record?.latestHeme ?? null;
  const ped = props.record?.latestPed ?? null;
  const irf = props.record?.latestIrf ?? null;
  const srf = props.record?.latestSrf ?? null;

  return (
    <Grid
      item
      xs={12}
      sx={{
        backgroundColor: "rgba(0, 102, 204, 0.05)",
        m: 1,
        p: 1,
        height: "100%",
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          align="center"
          fontWeight="bold"
          sx={{ mb: 2 }}
        >
          {t("singlePharse.lastTreatment")}
        </Typography>
      </Grid>

      <Grid container item xs={12} flexDirection="row">
        <Grid item xs>
          <LastTreatmentBasicInfoItem
            label={t("patientDetailPage.treatmentInterval")}
            value={treatmentInterval}
            icon={<AccessTimeOutlinedIcon sx={{ color: "#99C2EB" }} />}
          />
          <LastTreatmentBasicInfoItem
            label={t("singlePharse.date")}
            value={date}
            icon={<CalendarMonthOutlinedIcon sx={{ color: "#99C2EB" }} />}
          />
          <LastTreatmentBasicInfoItem
            label={t("singlePharse.drugName")}
            value={drugName}
            icon={<VaccinesOutlinedIcon sx={{ color: "#99C2EB" }} />}
          />
        </Grid>

        <Divider orientation="vertical" sx={{ height: "auto", mx: 1 }} />

        <Grid item xs>
          <CSTAndVAStatusItem label="CST" value={cst} status={cstStatus} />
          <CSTAndVAStatusItem label="VA" value={va} status={vaStatus} />

          <Divider sx={{ my: 1 }} />

          <LastTreatmentOtherInfo heme={heme} ped={ped} irf={irf} srf={srf} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LastTreatmentInfo;
