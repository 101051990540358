import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { useQueries, useMutation } from "@tanstack/react-query";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { userGetData, getInstituteInfo } from "../../../services/user";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import AccountInfo from "../../RegistrationPage/AccountInfo";
import ConfirmButtoms from "./ConfirmButtoms";
import PersonalInfo from "./PersonalInfo";
import Security from "./Security";
import nameYup, { nameOptionalYup } from "../../../utils/helperFunction/yupValidations/name";
import {
  mobileLocalYup,
  mobileNotRequiredYup,
} from "../../../utils/helperFunction/yupValidations/mobile";
import { passwordSimpleOptionalYup } from "../../../utils/helperFunction/yupValidations/password";
import idYup from "../../../utils/helperFunction/yupValidations/idYup";
import { getAllDBConstants } from "../../../services/dbConstants";
import { useEffect, useState, useCallback } from "react";
import {
  UserProps,
  InstituteDetails,
} from "../../../utils/types/services/user";
import { mobileNumberPrefix } from "../../../utils/constants/general";
import _ from "lodash";
import PatientSupportProgram from "./PatientSupportProgram";

const schema = yup.object().shape({
  isMobileExists: yup.boolean(),
  firstName: nameYup,
  lastName: nameYup,
  nickName: nameOptionalYup,
  sector: yup.string(),
  mobileLocal: mobileLocalYup.test(
    "mobile-exists",
    "Duplicate HCP Mobile Number detected.",
    (val, data) => {
      return !data.parent.isMobileExists;
    }
  ),
  password: passwordSimpleOptionalYup,
  instituteDetails: yup.array().of(
    yup.object().shape({
      instituteMobile: mobileNotRequiredYup,
      /* instituteDistrict: yup
        .string()
        .test(
          "test-has-address",
          'required selection when "Institute Address" is input',
          (val, data) => {
            if (
              data.parent.instituteAddress !== "" &&
              (val === "-" || val == "")
            )
              return false;
            return true;
          }
        ),
      instituteAddress: yup
        .string()
        .test(
          "test-has-district",
          'required input when "Institute District" is selected',
          (val, data) => {
            if (data.parent.instituteDistrict !== "-" && val === "")
              return false;
            return true;
          }
        ),*/
    })
  ),
});

export type UserFormState = Pick<
  UserProps,
  | "firstName"
  | "lastName"
  | "nickName"
  | "email"
  | "instituteDetails"
  | "password"
  | "sector"
> & {
  mobileLocal: string;
  mobilePrefix: string;
  isMobileExists?: boolean,
};

const UserProfile = () => {
  const [isInstituteInfo, setIsInstituteInfo] = useState<boolean>(false);
  const dummyInstProps = {
    instituteName: "",
    instituteType: -1,
    instituteAddress: "",
    instituteDistrict: "-",
    instituteMobile: "",
    instituteMobilePrefix: mobileNumberPrefix,
    Sunday: "",
    Monday: "",
    Tuesday: "",
    Wednesday: "",
    Thursday: "",
    Friday: "",
    Saturday: "",
    hideHours: false,
  };

  const [insDetails, setInsDetails] = useState<InstituteDetails[]>([
    { ...dummyInstProps },
    { ...dummyInstProps },
    { ...dummyInstProps },
  ]);
  const { mutateAsync } = useMutation(getInstituteInfo);

  let initialInstDetails: InstituteDetails[] = [
    { ...dummyInstProps },
    { ...dummyInstProps },
    { ...dummyInstProps },
  ];

  const [{ data: dbConstants }, { data }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.DB_CONSTANTS],
        queryFn: getAllDBConstants,
      },
      {
        queryKey: [REACT_QUERY_KEYS.USER_DATA],
        queryFn: userGetData,
      },
    ],
  });

  useEffect(() => {
    if (data && data.data) {
      setValue("sector", data.data.sector ?? "");
      
      const run = async () => {
        const instituteInformation = await mutateAsync(data?.data?.email ?? "");
        if (instituteInformation?.success) {
          setIsInstituteInfo(true);
          const instDetails = instituteInformation.data.instituteDetails;
          const tempInstDet = _.cloneDeep(instDetails ?? []);
          tempInstDet.forEach((item: any, index: number) => {
            const mob = instDetails[index].instituteMobile || "";

            tempInstDet[index].instituteMobilePrefix =
              mob !== ""
                ? mob.substring(0, mobileNumberPrefix.length)
                : mobileNumberPrefix;
            tempInstDet[index].instituteMobile =
              mob !== "" ? mob.substring(mobileNumberPrefix.length) : "";
            tempInstDet[index].instituteDistrict =
              instDetails[index].instituteDistrict || "-";
            if (
              item.Sunday === "Closed" &&
              item.Monday === "Closed" &&
              item.Tuesday === "Closed" &&
              item.Wednesday === "Closed" &&
              item.Thursday === "Closed" &&
              item.Friday === "Closed" &&
              item.Saturday === "Closed"
            ) {
              tempInstDet[index].hideHours = true;
            } else {
              tempInstDet[index].hideHours = false;
            }
          });
          setInsDetails(tempInstDet);
          initialInstDetails = tempInstDet;
          setValue("instituteDetails", tempInstDet);
        }
      };
      run();
    }
  }, [data?.data]);

  const useFormMethods = useForm<UserFormState>({
    defaultValues: {
      firstName: data?.data?.firstName ?? "",
      lastName: data?.data?.lastName ?? "",
      nickName: data?.data?.nickName ?? "",
      email: data?.data?.email ?? "",
      mobileLocal: (data?.data?.mobile ?? "").substring(
        mobileNumberPrefix.length
      ),
      mobilePrefix: (data?.data?.mobile ?? "").substring(
        0,
        mobileNumberPrefix.length
      ),
      password: "",
      sector: data?.data?.sector ?? "",
      instituteDetails: insDetails,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { setValue, reset } = useFormMethods;

  const resetFormInfo = () => {
    reset({
      firstName: data?.data?.firstName ?? "",
      lastName: data?.data?.lastName ?? "",
      nickName: data?.data?.nickName ?? "",
      email: data?.data?.email ?? "",
      mobileLocal: (data?.data?.mobile ?? "").substring(
        mobileNumberPrefix.length
      ),
      mobilePrefix: (data?.data?.mobile ?? "").substring(
        0,
        mobileNumberPrefix.length
      ),
      password: "",
      sector: data?.data?.sector ?? "",
      instituteDetails: initialInstDetails,
    });
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      direction={"row"}
      alignItems={"center"}
      sx={{ backgroundColor: "background.paper", padding: "3%" }}
      spacing={{ xs: 1, sm: 1 }}
    >
      <FormProvider {...useFormMethods}>
        <form style={{ width: "100%" }}>
          <PersonalInfo />
          <PatientSupportProgram />
          <AccountInfo isInstituteInfo={isInstituteInfo} mode="edit" />
          <Security />
          <ConfirmButtoms resetFormInfo={resetFormInfo} />
        </form>
      </FormProvider>
    </Grid>
  );
};

export default UserProfile;
